.postContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.postImgContainer {
    min-height: 40vw;
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.postImage {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.postTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 85%;
}

.postContentContainer {
    max-width: 65%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 2;
}

.postContentContainer > p {
    margin-top: 5px;
}

.tagContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
    height: 35px;
    margin-bottom: 5px;
    background-color: rgba(0, 195, 255, 0.329);
}
