@media screen and (min-width: 577px) and (max-width: 775px) {
  .content-selection{
      width:654px; /*hovered over the image to get the actual width because the "box model" property was incorrect */
      
  }
}


.skillbridge-modal{
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.heroVideoSection {
  position: relative;
  height: 40%;
  overflow: hidden;
}

.heroVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stillHeroImage{
  height:100%;
  position: absolute;
  left:-60px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0.02));
}

.bg-white-06 {
  position: relative;
  z-index: 1; /* Place it above the video and overlay */
}

.heroText{
  z-index: 3;
}

.content-selection{
   width:440px !important;
   height:614px;
   overflow: hidden;
}

.content-container-service{
  max-width: 421px;
  min-width: 1vw;
  border-radius: 20px;
  height: 720px;
  margin-bottom: 10px;
}

.content-container-service-row2{
  max-width: 421px;
  min-width: 1vw;
  border-radius: 20px;
  height: 512px;
  margin-bottom: 10px;
}
@media screen and (max-width: 900px) {
  .content-container-service{
    height: auto;
  }
}

.content-selection-service{
  width:440px !important;
   height:auto;
   overflow: hidden;
}

.content-selection-service p {
  font-size: 17px;
}

.content-selection > a{
  color: black;
  text-decoration: none;
}

.content-container{
   max-width: 421px;
   min-width: 1vw;
   border-radius: 20px;
   height: 550px;
}

/*mobile devices*/
@media screen and (max-width: 377px) {
  .content-container{
    height:auto
  }

  .content-selection{
    height:auto
  }
}

.service-images{
  width:100%;
  height:220px;
  border-radius: 20px;
}

.service-container{
  display: flex;
  justify-content: center;
  margin-inline: 0 !important;
}

.content-container > h5{
  text-align: center;
}

.content-container:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-box-shadow: 0 10px 36px 0 rgba(22, 24, 26, 0.1);
  box-shadow: 0 10px 36px 0 rgba(22, 24, 26, 0.1);
}

.modal-headers > h5{
  font-size: 30px;
}

.footer-buttons-layout{
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end !important;
  flex-wrap: nowrap !important;
}

.federal-agency-button{
margin: 0 50%;
position: relative;
top: 25px;
translate: -50% -50%;
height: 40px;
width: 130px;
}

.cmmc-button{
margin: 0 50%;
position: relative;
top: 26px;
translate: -50% -50%;
height: 40px;
width: 130px;
}

.partnership-button{
margin: 0 50%;
position: relative;
top: 100px;
translate: -50% -50%;
height: 40px;
width: 130px;
}