/* hero section */
:root {
    --shadow: 1px 1px 2px rgba(70, 70, 70, 0.8);
}

a {
    text-decoration: none !important;
    color: black !important;
    font-size: 1.5vw;
}

button {
    padding: 0.5em 1em;
    color: white;
    border-radius: 10px !important;
}

video {
    filter: brightness(40%);
}

ul {
    margin: 0 !important;
    padding: 2em 1em;
}

header {
    margin-left: 5em;
}

.services-header h1,
.fc-header h1,
.blog-header h1,
.core-header h1 {
    font-size: 4vw;
    text-shadow: 1px 1px 2px rgba(72, 72, 72, 0.8);
    font-weight: 600;
}

/* hero section */
.hero-container {
    background-color: #000000;
    position: relative;
}

.video-container,
.video-container video {
    height: 100%;
    width: 100%;
}

.hero-content {
    position: absolute;
    top: 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3em;
    overflow: hidden;
}

.hero-content-items {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

#hero-logo {
    height: 20vw;
    min-width: 20vw;
}

#hero-header {
    font-size: 4vw;
}

#hero-info {
    font-size: 2vw;
}

/* core offerings section */
.core-container {
    width: 100%;
    height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2em 0;
}

.core-bg-img {
    transform: rotateX(180deg);
}

.services-bg-img {
    transform: rotateY(180deg);
}

.core-header {
    width: calc(100% - 6em);
}

.core-content {
    display: flex;
    height: 80%;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    background-color: white;
}

.core-left {
    width: 45%;
}

.core-left img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.core-right {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.core-right ul {
    list-style-type: none;
}

.list-item {
    font-size: 1.5vw;
    margin-bottom: 1em;
    font-weight: 500;
}

/* services section */
.services-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40vw;
    position: relative;
}

.bg-div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.bg-img {
    width: 100%;
    height: 100%;
}

.services-cards {
    display: flex;
    justify-content: space-evenly;
    gap: 3em;
    padding: 0 4em;
}

.services-card {
    display: flex;
    justify-content: center;
    width: 30vw;
}

.services-card-content {
    width: 100%;
    height: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    color: black;
    text-decoration: none;
    padding: 0;
    background-color: white;
    border: 1px solid #cccccc;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    position: relative;
    transition: all 0.15s ease-out;
    top: 0;

    &:hover {
        transition: all 0.15s ease-in;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px,
            rgba(0, 0, 0, 0.23) 0px 4px 8px;
        top: -15px;
    }
}

.services-card-content img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    height: 12vw;
}

.services-card-text-content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em 0;
}

.services-card-text-content h5 {
    font-size: 1.5vw;
}

/* federal contractors section */
.fc-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4em 0;
    position: relative;
}

.cards-two-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
}

.fc-card {
    display: flex;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.55);
    border-radius: 5px;
    width: 90%;
}

.fc-card-img-div {
    min-width: 40%;
}

.fc-card-img {
    width: 100%;
    min-height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.fc-card-content {
    background-color: white;
    width: 60%;
    padding: 2em 4em;
    border-radius: 5px;
}

.fc-card-content h3 {
    font-size: 2vw;
}

.fc-card-content p {
    font-size: 1.5vw;
}

.flip img {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* blog section */
.blog {
    padding: 2em 0;
}

.blog-header {
    margin-left: 0;
}

.blog-container {
    margin: 2em 20em 0em 20em;
}

.blog-section {
    height: 40vw;
}

.carousel-inner {
    height: 100%;
}

.carousel-caption {
    height: 100%;
}

.carousel-item {
    height: 100%;
}

.carousel-img {
    width: 100%;
    height: auto;
    filter: brightness(50%);
}

.carousel-caption {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 1.5vw;
    z-index: 2;
}

.carousel-control-prev span,
.carousel-control-next span {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

hr {
    display: none;
}
