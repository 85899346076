/* Logo Card Starts */
.logoCardsContainer {
    width: 90%;
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3vw;
}

.logoCards {
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
}

.cardContainer {
    width: 31vw;
    border-radius: 10px;
    box-shadow: 0px 10px 19px 3px rgba(0, 0, 0, 0.57);
    margin:15px auto;
}

.topContainer {
    display: flex;
    align-items: center;
    color: white;
    background-color: rgba(40, 44, 48, 0.7);
    border-radius: 10px 10px 0px 0px;
    padding: 5px;
    gap: 15px;
}

.logoContainer {
    border-radius: 10px;
}

.skillBridge {
    background-color: rgb(124, 178, 240);
    padding: 5px;
}

.svdosb {
    background-color: white;
}

.titleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    padding: 10px;
    line-height: 2;
}
/* Logo Cards Ends */

/* Large Logo Section */
.largeSection {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.parent {
    width: 70%;
}

.contentRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.largeLogoContainer {
    width: 20vw;
}

.largeLogo {
    width: 100%;
    height: 100%;
}
/* Large Logo Section Ends */
