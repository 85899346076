@media screen and (max-width: 631px) {
    .featured {
        height: 70vw;
        padding: 5px;
        width: 100%;
    }

    .featuredPost {
        position: relative;
        background: none;
        padding: 5px;
    }

    .featuredImg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        filter: brightness(0.5);
    }

    .arrow {
        display: none;
    }

    .featuredContent {
        width: 100%;
    }

    .featuredContent > h1 {
        font-size: 10vw;
    }

    .featuredContent > p {
        font-size: 5vw;
        margin: 0;
    }

    .recent {
        padding: 5px;
    }

    .titleContainer {
        flex-direction: column;
    }

    .filterContainer {
        flex-direction: column;
    }

    .recentPost {
        width: 100%;
        padding: 0px;
    }

    .recentPost:hover {
        transform: scale(1.02);
    }

    .recentTitle {
        font-size: 7vw;
    }

    .tag > p {
        font-size: 4vw;
    }

    .recentDescr {
        font-size: 4vw;
    }

    .date {
        font-size: 4vw;
    }
}

@media screen and (min-width: 632px) and (max-width: 1400px) {
    .featured {
        height: 40vw;
        padding: 5px;
        width: 100%;
    }

    .recent {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 5px;
    }

    .recentPostsContainer {
        gap: 15px;
    }

    .recentPost {
        width: 45vw;
    }
}

@media screen and (max-width: 630px) {
    .mainContainerBlog {
        margin: auto;
        width: 90%;
    }
}
