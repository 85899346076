/* Calendly Section Starts */
.calendlySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 60px 0;
    gap: 3em;
}

.calendlyTitle {
    width: 80%;
}

.calendlyCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    width: 100%;
    height: 35vw;
}

.calendlyCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 35vw;
    max-width: 35vw;
    min-height: 100%;
    box-shadow: 0px 10px 19px 3px rgba(0, 0, 0, 0.57);
    border: 3px solid rgba(153, 153, 153, 0.301);
    border-radius: 10px;
    transition: box-shadow 0.3s transform 1s ease-in-out;
    transition: transform 0.3s ease-in-out;
    background: rgb(241, 241, 241);
    background: linear-gradient(
        133deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.calendlyCard:hover {
    cursor: pointer;
    box-shadow: 0px 10px 25px 5px rgba(0, 152, 212, 0.39);
    transform: scale(1.05);
}

.link {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.descrContainer {
    padding: 0.8em;
}

.timeContainer {
    position: relative;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5vw;
    z-index: 1;
}

.timeBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 0px 0px 10px 10px;
}

.time {
    margin: 0;
    padding: 0;
}

.hr {
    margin-bottom: 15px;
    background-color: rgba(0, 0, 0, 0.171);
    height: 3px;
    width: 100%;
    border-radius: 10px;
}
