.grandParent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.carouselParent {
    width: 90%;
    height: 100%;
}

.carouselSlide {
    min-width: 100% !important;
    min-height: 40vw !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slideContent > h2 {
    font-size: 2.5vw;
}

.slideContent > h4 {
    font-size: 1.5vw;
}

.secondSection > h4 {
    font-size: 1.5vw;
}

.secondSection > div > h4 {
    font-size: 1.5vw;
}

.slideImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.75);
}

.slideContent {
    height: 100%;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.473);
    color: white;
    padding: 30px;
    border-radius: 10px;
}

.secondSection {
    width: 100%;
    gap: 30px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.dividerBlack {
    border: 2px solid;
    background: black;
    opacity: 100;
}

.dividerWhite {
    border: 2px solid;
    background: white;
    opacity: 100;
}

.hasCols {
    display: none;
}
