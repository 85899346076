/* xs screen */
@media screen and (max-width: 300px) {
    .heroContainer {
        height: 100%;
    }

    .calendlySection {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .calendlyCards {
        height: 100%;
        width: 100%;
    }

    .link,
    .calendlyCard {
        min-width: 95%;
        height: 100%;
    }

    .descrContainer {
        padding: 1em;
    }

    .timeContainer {
        height: 10%;
    }

    .calendlyTitle {
        width: 95%;
    }

    hr {
        margin-bottom: 0 !important;
    }
}

/* sm screens */
@media screen and (min-width: 301px) and (max-width: 767px) {
    .heroContainer {
        height: 46vh !important;
    }

    .heroContent {
        padding: 0 2em !important;
    }

    .calendlySection {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .calendlyCards {
        height: 100%;
    }

    .link,
    .calendlyCard {
        min-width: 95%;
        height: 100%;
        justify-content: center;
    }

    .timeContainer {
        height: 20%;
    }

    .calendlyTitle {
        width: 95%;
    }

    hr {
        margin-bottom: 0 !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .heroContent {
        padding: 0 2em !important;
    }

    .heroImage,
    .overlayLeft {
        height: 100% !important;
        width: 100% !important;
    }

    .calendlyTitle {
        width: 95%;
    }

    .calendlySection {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .calendlyCards {
        height: 100%;
    }

    .calendlyCard {
        min-width: 42vw;
    }

    .descrContainer {
        padding: 2em 1em;
    }

    .timeContainer {
        height: 30% !important;
    }

    hr {
        margin-bottom: 0 !important;
    }
}

@media screen and (min-width: 993px) {
    .heroContainer {
        height: 70vh !important;
    }

    .heroContent {
        padding: 0 2em !important;
    }

    .heroImage,
    .overlayLeft {
        height: 100% !important;
        width: 100% !important;
    }

    .calendlySection {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .calendlyCards {
        height: 100%;
    }

    .calendlyCard {
        min-width: 37vw;
    }

    .timeContainer {
        height: 30% !important;
    }
}
