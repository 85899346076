@media screen and (max-width: 600px) {
    .postImgContainer {
        width: 95%;
        min-height: 70vw;
    }

    .postTitleContainer {
        width: 95%;
        align-items: center;
        text-align: center;
    }
    .postContentContainer {
        min-width: 95%;
    }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
    .postImgContainer {
        width: 95%;
        min-height: 50vw;
    }
    .postContentContainer {
        min-width: 80%;
    }
}
