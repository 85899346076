.carousel-container .carousel-control-prev,
.carousel-container .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: gray; /* Changed to gray */
    font-size: 2rem;
    z-index: 1;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
}

.carousel-container .carousel-control-prev {
    left: 0; /* Position at the beginning */
    margin-left: 10px; /* Optional margin for spacing */
}

.carousel-container .carousel-control-next {
    right: 0; /* Position at the end */
    margin-right: 10px; /* Optional margin for spacing */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .carousel-control-prev {
        left: 5px;
    }

    .carousel-control-next {
        right: 5px;
    }

    .services-cards {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .services-card {
        width: 90%; /* Full width on small screens */
        max-width: none;
    }
}