.mainBox{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    background-image: url("/src/assets/background_image/wlaptop.jpg");
    background-size: cover; 
}

.row{
    display:flex;
    flex-direction: row;
}

.col{
    display:flex;
    flex-direction: column;
}

.selection{
    display:flex;
    flex-direction: column;
}

form{
    padding: 35px;
    background-color: rgb(255 255 255 / 50%);
    border-radius: 20px;
    margin: 25px 0;
}

.mainBox input {
    margin-bottom: 15px;
}

.mainBox select {
    margin-bottom: 15px;
}

.mainBox input[type=radio] {
    margin-right: 10px;
}

.mainBox input[type=checkbox] {
    margin-right: 10px;
}