.featured {
    height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.featuredLink {
    width: 100%;
    height: 100%;
}

.featuredPost {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 0px 30px;
    color: white;
    overflow: hidden;
    border: 2px solid rgb(58, 58, 58);
    background: rgb(0, 0, 0);
    background: radial-gradient(
        circle,
        rgb(68, 68, 68) 0%,
        rgb(0, 0, 0, 1) 90%
    );
    transition: transform 0.2s;
}

.parentTagContainer {
    display: flex;
    gap: 15px;
    align-content: center;
}

.tag {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.233);
    border: 2px solid rgba(0, 0, 0, 0.226);
}

.tag > p {
    margin: 0;
}

.category {
    background-color: rgba(1, 76, 99, 0.842);
    color: white;
}

.new {
    background-color: rgba(0, 255, 85, 0.753);
}

.arrow {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 255, 85);
}

.featuredContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;
}

.featuredContent > h1 {
    font-size: 5vw;
}

.featuredContent > p {
    font-size: 1.6vw;
}

.featuredImg {
    width: auto;
    height: 100%;
}

.featuredDescription {
    font-weight: lighter;
}

.featuredPost:hover {
    transform: scale(1.02);
    cursor: pointer;
    box-shadow: 0px 10px 12px 4px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.2s transform 0.3s;
}

.recent {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 30px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterContainer {
    display: flex;
    align-items: center;
    gap: 15px;
}

#filter {
    height: 30px;
    border-radius: 10px;
}

.recentPostsContainer {
    display: flex;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    border-top: 2px solid rgb(63, 63, 63);
    padding-top: 30px;
}

.recentPost {
    width: 30vw;
    border-radius: 15px;
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 15px;
    height: min-content;
    color: white;
    border: 2px solid rgb(58, 58, 58);
    background: rgb(0, 0, 0);
    background: radial-gradient(
        circle,
        rgb(68, 68, 68) 0%,
        rgb(0, 0, 0, 1) 90%
    );
}

.recentPost:hover {
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 10px 12px 4px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
    cursor: pointer;
}

.imageContainer {
    max-height: 340px;
    overflow: hidden;
    border-radius: 15px 15px 0px 0px;
}

.recentContent {
    padding-left: 5px;
    padding-left: 5px;
}

.recentImg {
    width: 100%;
    height: auto;
    border-radius: 0px;
    margin: 0px;
}

.recentDescr {
    font-weight: lighter;
}

.blankF {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid rgb(58, 58, 58);
    background: rgb(46, 46, 46);
    animation: myAnim 3s cubic-bezier(0.85, 0, 0.15, 1) 0s infinite normal
        forwards;
}

.blank {
    width: 30vw;
    border-radius: 15px;
    margin-bottom: 15px;
    height: 25vw;
    border: 2px solid rgba(58, 58, 58, 0.158);
    background: rgb(46, 46, 46);
    animation: myAnim 3s cubic-bezier(0.85, 0, 0.15, 1) 0s infinite normal
        forwards;
}

@keyframes myAnim {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0.9;
    }
}

/* Resoinsiveness for text */

.mainContainerBlog {
    margin: auto;
    width: 100%;
}
