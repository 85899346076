.heroImageSkillbridge {
    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    text-align: center; /* Centers text */
}

.text-with-border {
    color: black;
    text-shadow: 
        -1px -1px 0 white,  
        1px -1px 0 white,
        -1px 1px 0 white,
        1px 1px 0 white;
}

.bg-white-06 {
    height: 60vh; /* Adjusted to 30% view height */
}