.btn-dark,
.btn-dark:active,
.btn-dark:visited {
  background-color: #216380 !important;
}

.btn-dark:hover {
  background-color: white !important;
  color: #216380 !important;
}

@media screen and (max-width: 1000px) {
  .heroHeight {
      height: 35vh;
  }
}

@media screen and (min-width: 1000px) {
  .heroHeight {
      height: 53vh;
  }
}
.flagImg {
  position: relative;
  overflow: hidden;
}

/*old
#flagImage {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: -2;
} */

.heroText {
  color: rgb(4, 26, 99);
}

.mainContainerGovernment{
margin: auto;
width: 90%;
}

/*new hero image sizing*/
#flagImage {
    width: 113vw;
    height: auto;
    position: absolute;
    z-index: -2;
    left: -61px;
}

.heroTextGov {
  position: relative;
}

.heroTextGov h1 {
  padding: 19px;
  /*background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px; */
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.governmentHeroText{
  text-shadow: -1px 0px white;

}
/*
.text-center.container {
  background-color: rgba(255, 255, 255, 0.3);

}*/

.government-skillbridge{
width:100%;
height:auto;

}

@media screen and (max-width:600px){
  .government-skillbridge{
    display: none;
  }
}

.gov-container  *{
  padding:0;
}

.gov-container ul {
  font-weight: 100;
  margin-left: 25px !important;
}
.separator{
  height: 3px;
  background-color: red;
  border: none;
  margin-bottom: 10px;
}

.gov-container{
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.gov-container-box{

width:100%;
margin: 20px;
padding:40px;
min-width:300px;
height: 100%;
}

.gov-container-logo {
  display: flex;
  justify-content: center;
  width: 100%;
  height:auto;

}

.logo-group > img{
width:30%;
height:auto
}

.container-grey{
  background-color: rgb(233, 233, 233);
  color:darkblue
}

.container-blue{
  background-color: darkblue;
  color:white;
}

.capabilities-statement{
  background-color: darkblue;
  color:white;
  border-bottom: 3px solid red;
  display:flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  padding:20px;
}

@media screen and (max-width: 1020px){
  .capabilities-statement{
    background-color: darkblue;
    color:white;
    border-bottom: 3px solid red;
    display:flex;
    flex-direction: column;
    padding:20px;
  }
  .capabilities-statement-container{
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
  }
}

.last-cap{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.last-cap > * {
  margin:0 20px;
}

.middle-cap{
width:50%;
margin:0 20px;
}

#logo-VCSG{
  min-width:150px;
  min-height:150px;
  max-height:200px;
  max-width:200px;
  margin:auto
}

@media screen and (max-width:900px){
  #logo-VCSG{
    display: none;
   
  }
  .capabilities-statement-container{
    justify-content: space-between;
  }
}

@media screen and (max-width:590px){
  .middle-cap {
    width: 100%;
    margin:auto;
  }

  .middle-cap  p {
    margin-bottom: 0;
  }

  .middle-cap h2,
  .middle-cap h3 {
    text-align: center;
  }
}

.cont-logo{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: center;
}

.cont-logo > img{
  width:150px;
  height:150px;
  margin: 0 5%;

}

.gov-contact-sub{
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-around;
}

@media screen and (max-width:600px){

  .cont-logo > img{
    width: 80px !important;
    height: 80px !important;
  }
}

.gov-con {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 35%;
  align-items: center;
  margin: 20px;
  min-width: 300px;
}

.logo-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}