

.nav{
    font-size: 18px;
    
  }
  
  .nav-link{
    margin: auto 15px;
  }

img{
  width:auto;
  height:70px;
  
}


  
.navbar-toggler.collapsed{
  position: absolute;
  right: 12px;
  top: 2.5vh;
}