.nav{
  font-size: 18px;
  
}

.nav-link{
  margin: auto 15px;
  cursor:pointer;
  border-radius: 10px;
  color:black
}

.logo-image{
  cursor:pointer;
}
.nav-link:hover {
  background-color: rgba(119, 136, 153, 0.148);
}


.btn-dark,
.btn-dark:active,
.btn-dark:visited {
  background-color: #216380 !important;
}

.btn-dark:hover {
  background-color: white !important;
  color: #216380 !important;
}

.book-now-button {
  position: absolute;
   bottom: 20px; 
   right: 0px;
}


@media screen and (max-width: 991px) {
  .book-now-button{
    position: relative;
    top:30px;

  }
}

* {

  font-family: "Roboto" !important;
}

.skillbridge-icon {

   background-image:linear-gradient(to right, rgb(170, 202, 238), rgb(105, 167, 238));
}

.logo-background{
background-color: white;
border-radius: 10px;
}


/*Footer CSS*/
.send-email-tab{
  font-size: smaller;
}

.consultant{
width: 330px;
border: 2px solid grey;
border-radius:10px;
padding:20px;
margin:auto;
}

.consultant-heading{
  text-align: center;
  font-size: 32px;
}

.footer-2{
  padding: 1.5em 0 1.5em;

}


.right-footer{
  text-align: right;
}

.center-footer{
  text-align: center;
}

@media screen and (min-width: 577px) and (max-width: 775px) {
  .center-footer{
    margin-left: 50px;
  }
}

@media screen and (max-width: 575px) {
  .left-footer{
    text-align: center;
  }

  .center-footer{
    margin-left: 0;
  }
  
  .right-footer{
    text-align: center;
  }


}

.offeringsLogo {
  margin: 20px
}


.image-container {
  display: flex;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
}

.text-center{
  text-align: center;
}

.text-justified{
  text-align: justify;
}
.padding-top{
  padding-top: 70px;
}

.padding-bottom{
  padding-bottom: 70px;
}

.align-right{display:flex;
justify-content: right;
}
/*Services CSS*/

/*Close Services CSS*/


/*Home Page CSS*/
.heroContentImg{
float:right;
width: 300px;
height:300px;
}

.heroContentText{
  color:black;
}
/*end of Home Page CSS*/

@media screen and (max-width: 775px) {
  .heroContentImg-content{
    display:none;
  }


}

.margin{
  margin:0;
}

.heroImage{
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;

}

.heroText{
  padding: 160px 30px;
  color: black !important;
}

.bg-white-06 {
  background: rgba(255, 255, 255, 0.6);
  }
  
  
.scrolltotop.scrolltotop-show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}


.scrolltotop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  z-index: 992;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}


.button-circle.button-circle-dark {
  background: #202428;
  border: 0;
  color: white;
  }

.button-circle-md {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.button-circle {
  background: #e4e8ec;
  display: inline-block;
  border: 1px solid #e4e8ec;
  border-radius: 50%;
  color: #121518;
  text-align: center;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}

.bi-arrow-up-short{
  color: white;
}
        


.appointmentButton{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.footerContainer{
  padding-top: 1.5rem;
}



@media screen and (max-width: 280px){
  .heroText{
    padding: 99px 30px;
  }
}

/*p { 
  font-size: 24px;
} */