@media screen and (min-width: 1400px) {
    .bottom-table{
    height:382px;
    }

    .top-table{
        height:273px;
    }

    .table-width{
        width:654px;
        }
}

@media screen and (min-width: 755px) {
    .image-resize{
        max-width:100px;

    }
}
    .boxLife{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-content: center;
    }
    .heroImgz{
        display: flex;
        margin:auto;
        flex-direction: column;
        align-items: center;
        height: 300px;
        text-align: center;
        justify-content: space-around;
    }
    .aboutTiles{
        display:flex;
        align-items: center;
    }

    .tileParent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(241, 241, 241);
        max-width: 500px;
        border: solid 3px rgb(90, 90, 90);
        margin: 30px 30px;
        border-radius: 10px;

    }

    @media screen and (max-width: 768px) {
        .tileParent {
        margin-left: 0px;
        margin-right: 0px;
        }
    }

    .tileTop{
        justify-content: space-between;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: rgb(170, 170, 170);
        border-radius: 6px 6px 0px 0px;
        margin-bottom: 10px;
        padding: 7px;

    }

    .bodyParagraph{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-inline: 4%;
    }


    .tileTopIMGs{
        margin-right:10px
    }
    /*mobile devices*/

    .mainContainer{
        width: 90%;
        margin: auto;
      }
    
      .tileParent {
        width: 90vw;
      }

      @media screen and (max-width: 385px) {
        .tileTopIMGs{
            display:none
        }
      }


      .tileTop h5 {
        margin-bottom: 0px;
    }

 @media screen and (min-width: 798px){
    .aboutFont{
        font-size: 22px;
    }
}