.iconsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.icons {
    width: 85%;
    display: flex;
    justify-content: space-between;
}

.icon {
    margin-bottom: 16px;
}

.formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 60px;
}

.form {
    width: 85% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactSubmitButton {
    text-align: center !important;
    margin: auto;
    vertical-align: middle !important;
    padding: 10px 0px !important;
  /*  max-width: 140px;*/
}

