@media screen and (max-width: 768px) {
    a {
        font-size: 1.5vh;
    }

    header {
        margin: 0;
        text-align: center;
    }

    .hero-container {
        /* background: linear-gradient(rgba(1, 1, 1, 0.25), rgba(1, 1, 1, 0.35)),
            url(/images/home-images/responsive-hero.jpg); */
        background-size: 131vw;
        padding: 15vw 0px;
        background-repeat: no-repeat;
    }

    .video-container {
        opacity: 0;
    }

    .bg-div,
    .bg-img {
        display: none;
    }

    .hero-content-items {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #hero-header {
        font-size: 4vh;
    }

    #hero-info {
        font-size: 2.45vh;
    }

    #hero-logo {
        display: none;
    }

    .services-header h1,
    .fc-header h1,
    .blog-header h1,
    .core-header h1 {
        font-size: 5vh;
    }

    .core-container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 2em 1em;
    }

    .core-header {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }

    .core-header h1 {
        font-size: 5vh !important;
        text-align: center;
    }

    .core-content {
        flex-direction: column;
    }

    .core-left,
    .core-right {
        width: 100%;
    }

    .core-left {
        height: 60vw;
    }

    .core-left img {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
    }

    .core-right ul {
        margin: 0;
        padding: 2em 1em;
    }

    .core-right li {
        font-size: 2.5vh;
        text-align: center;
    }

    .services-container {
        height: 100%;
        padding: 2em 0;
    }

    .services-cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 2em;
        padding: 0 2em;
    }

    .services-card {
        width: 100%;
        height: 100vw;
    }

    .services-card-content img {
        height: 50%;
        width: 100%;
    }

    .services-card-text-content h5 {
        font-size: 3vh;
    }

    .fc-card {
        width: 100%;
        flex-direction: column-reverse;
    }

    .cards-two-container {
        padding: 0 2em;
    }

    .fc-card-content {
        width: 100% !important;
        padding: 2em 1.5em;
        text-align: center;
    }

    .fc-card-img-div {
        width: 100%;
        height: 40vw;
    }

    .fc-card-content h3 {
        font-size: 3vh;
    }

    .fc-card-content p {
        font-size: 2.5vh;
    }

    .flip {
        flex-direction: column;
    }

    .blog-container {
        margin: 0;
    }

    .blog-section {
        height: 60vw;
    }

    .blog-header h1 {
        font-size: 32px !important;
        text-align: center;
    }

    .carousel-caption h3 {
        display: none;
        font-size: 24px !important;
    }

    .carousel-caption p {
        font-size: 18px !important;
    }
    .carousel-container {
        position: relative;
        align-items: center;
    }
}

@media screen and (max-width: 1100px) and (min-width: 769px) {
    .blog-container {
        margin: 2em 4em 0em 4em;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1600px) {
    .blog-container {
        margin: 2em 12em 0em 12em;
    }
}
