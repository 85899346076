.container1 {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: row;
}

.textContent, .imageContent {
    flex: 1 1 50%;
    padding: 20px;
}

.textContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.imageContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContent img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}

.boxLife{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
}

.heroImgz{
    display: flex;
    margin:auto;
    flex-direction: column;
    align-items: center;
    height: 300px;
    text-align: center;
    justify-content: space-around;
}
.aboutTiles{
    display:flex;
    align-items: center;
}

.tileParent1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*background-color: rgb(241, 241, 241);*/
    background-color:aliceblue;
    max-width: 500px;
    /*border: solid 3px rgb(90, 90, 90);*/
    border: solid 2.5px rgb(55, 55, 87);
    margin: 30px 30px;
    border-radius: 10px;
    min-width: 500px;

}

.tileTop1{
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    /*background-color: rgb(170, 170, 170);*/
    background-color:lightseagreen;
    border-radius: 6px 6px 0px 0px;
    margin-bottom: 10px;
    padding: 7px;

}

.bodyParagraph1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 4%;
    font-size: medium;
}


.tileTopIMGs{
    margin-right:10px
}


.mainContainer{
    width: 90%;
    margin: auto;
}
    
.tileParent {
    width: 90vw;
    min-width: 50%;
}

.tileTop1 h5 {
    margin-bottom: 0px;
    font-size: larger;
    text-align: right;
}

.breakup {
    background-color: #f0f0f0; /* Light gray background */
    padding: 20px; /* Adds padding for better spacing */
    border-radius: 10px; /* Optional: Adds rounded corners for a softer look */
    width: 100%;
    max-width: 1500px; /* Control the max width to keep the layout neat */
    margin: 20px auto; /* Center the container with space above and below */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

@media screen and (min-width: 1400px) {
    .bottom-table{
    height:382px;
    }

    .top-table{
        height:273px;
    }

    .table-width{
        width:654px;
    }

}

/*mobile devices*/

@media screen and (min-width: 798px){
    .aboutFont{
        font-size: 22px;
    }

}

@media screen and (max-width: 768px) {
    .tileParent {
    margin-left: 0px;
    margin-right: 0px;
    }

    .textContent, .imageContent {
        flex: 1 1 100%;
    }

    .tileParent1{
        max-width: 50vw;
        min-width: 50vw;
    }


}

@media screen and (min-width: 755px) {
    .image-resize{
        max-width:100px;

    }

}

@media screen and (max-width: 385px) {
    .tileTopIMGs{
        display:none
    }

}

