/* xs screen */
@media screen and (max-width: 300px) {
    .heroContainer {
        padding: 0 1em;
    }

    .heroImage {
        height: 100% !important;
        width: 100%;
    }

    .grandParent,
    .carouselParent {
        width: 100%;
    }

    .slideContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 80vh !important;
        width: 100%;
        padding: 0 2.5em;
    }

    .slideContent h2 {
        font-size: 16px;
    }

    .slideContent h4 {
        font-size: 14px;
    }

    .dividerWhite {
        margin-bottom: 1rem !important;
    }

    .secondSection {
        flex-direction: column;
    }

    .secondSection h4 {
        font-size: 12px !important;
    }

    .secondSection p {
        font-size: 10px !important;
    }
}

/* sm screens */
@media screen and (min-width: 301px) and (max-width: 767px) {
    .heroImage {
        height: 100% !important;
        width: 100%;
    }

    .grandParent,
    .carouselParent {
        width: 100%;
    }

    .slideContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 70vh !important;
        width: 100%;
        padding: 0 2.5em;
    }

    .slideContent h2 {
        font-size: 18px;
    }

    .slideContent h4 {
        font-size: 16px;
    }

    .dividerWhite {
        margin-bottom: 1rem !important;
    }

    .secondSection {
        flex-direction: column;
    }

    .secondSection h4 {
        font-size: 14px !important;
    }

    .secondSection p {
        font-size: 12px !important;
    }
}

/* md screens - lg screens*/
@media screen and (min-width: 767px) and (max-width: 992px) {
    .slideContent {
        padding: 30px;
    }
    .secondSection {
        flex-direction: column;
    }
}
