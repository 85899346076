@media only screen and (max-width: 400px) {
    .heroContainer {
        height: 85vw !important;
    }
    .heroImage {
        height: 90vw !important;
    }
}

.heroContainer {
    height: 30vw;
    position: relative;
    display: flex;
    justify-self: center;
    align-items: center;
    overflow: hidden;
    background-position: center;
    background-size: cover;
}

.heroContent {
    color: white;
    width: 100%;
    margin-left: 8%;
    z-index: 1;
}

.heroContent > h1 {
    font-size: 2.5rem;
}

.heroContent > h3 {
    font-size: 1.7rem;
    font-weight: 300;
    margin-bottom: 30px;
    margin-top: 25px;
}

.overlayLeft {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
        133deg,
        rgba(0, 0, 0, 1) 20%,
        rgba(108, 108, 108, 0) 100%
    );
}
