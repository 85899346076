@media screen and (min-width: 250px) and (max-width: 800px) {
    .logoCards {
        flex-direction: column;
        width: 95%;
        gap: 15px;
        align-items: center;
        justify-content: center;
    }

    .cardContainer {
        width: 95%;
    }

    .titleContainer {
        text-align: center;
    }
    .contentRow {
        flex-direction: column;
    }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .contentRow {
        flex-direction: column;
    }
}

@media screen and (min-width: 801px) and (max-width: 1400px) {
    .logoCards {
        width: 95%;
        gap: 15px;
    }

    .cardContainer {
        width: 45%;
    }
}

@media screen and (min-width: 1401px) {
    .logoCards {
        width: 85%;
        gap: 15px;
    }

    .cardContainer {
        width: 40%;
    }
}

@media screen and (min-width: 250px) and (max-width: 300px) {
    .title {
        font-size: 8vw;
    }
    .logoContainer {
        display: none;
    }
}

@media screen and (min-width: 301px) and (max-width: 500px) {
    .logoContainer {
        width: 45vw;
    }
    .svdosb {
        width: 20vw;
    }
    .cmmc {
        width: 20vw;
    }
}

@media screen and (min-width: 501px) and (max-with: 700px) {
    .logoContainer {
        width: 25vw;
    }
    .svdosb {
        width: 10vw;
    }
    .cmmc {
        width: 10vw;
    }
}

@media screen and (min-width: 701px) and (max-with: 1000px) {
    .logoContainer {
        width: 10vw;
    }
    .svdosb {
        width: 15vw;
    }
    .cmmc {
        width: 15vw;
    }
}
