.left{
    display:flex;
    align-items: center;
    padding: 15px 0;
}
.right{
    display:flex;
    align-items: center;
    flex-direction: row-reverse !important;
    padding: 15px 0;
}

.left div img {
    border-radius: 2%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.459);;
    border: 1px solid rgba(0, 0, 0, 0.267);
    margin: 3px;
}

.right div img {
    border-radius: 2%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.459);;
    border: 1px solid rgba(0, 0, 0, 0.267);
    margin: 3px;
}

.formButton{
    width: 250px;
    height: 35px;
    margin: 15px 50% 0 50%;
    transform: translate(-50%, 0);
}



    .left.row{
        display: flex;
        align-items: flex-start;
    }

    .right.row{
        display: flex;
        align-items: flex-start;
    }

    .womenImage{
        margin-bottom: 20px !important;
    }

    .womentText{
        font-size: 170%;
    }

    .womenMessage{
        font-size: 125%;
    }

    .right img{
        min-width:456px
    }

    .left img{
        min-width:456px
    }

@media screen and (max-width: 1400px){
    .womentText{
        font-size: 1rem;
    }
}

.womenMessageColor{
    color:blue;
}