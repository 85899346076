/* xs screen */
@media screen and (max-width: 300px){
    .heroContainer {
        height: 46vh !important;
    }

    .heroContent{
        text-align: center;
        margin: 0;
    }

    .icons{
        display:flex;
        flex-direction: column;
        gap: 1em;
    }

    .icons div{
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        font-size: 12px;
    }

    .formContainer button{
        width: 50% !important;
    }
}

/* sm screens */
@media screen and (min-width: 301px) and (max-width: 767px){
    .heroContainer, .heroContent{
        height: 46vw;
    }

    .heroImage{
        height: 100%;
        width: 100%;
    }

    .heroContent{
        text-align: center;
        margin: 0;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

    .icons{
        display:flex;
        flex-direction: column;
        gap: 1em;
    }

    .icons div{
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .formContainer button{
        width: 35% !important;
    }
}

/* md screens - lg screens*/
@media screen and (min-width: 767px) and (max-width: 992px){
    .heroContainer, .heroContent{
        height: 46vw;
    }

    .heroImage{
        height: 100%;
        width: 100%;
    }

    .heroContent{
        text-align: center;
        margin: 0;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

    .icons{
        gap: 2em;
    }

    .icons div{
        font-size: 17px;
        width: 25%;
        overflow-wrap: break-word;
        display:flex;
        flex-direction: column;
    }
}