@media screen and (max-width: 1200px) and (min-width: 701px) {
    .ctaParent {
        min-height: 35vw;
    }
    .ctaContent > h1 {
        font-size: 3vw;
    }
    .ctaContent > h4 {
        font-size: 2vw;
    }
    .ctaContent > a > button {
        font-size: 2vw;
    }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
    .leftCTA,
    .rightCTA {
        justify-content: center !important;
    }

    .ctaParent {
        min-height: 40vh;
    }
    .ctaContent {
        min-width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .alignRight {
        margin-right: 0% !important;
    }
    .alignLeft {
        margin: 0% !important;
    }
    .ctaContent > h1 {
        font-size: 4vw;
    }
    .ctaContent > h4 {
        font-size: 3.5vw;
    }
    .ctaContent > a > button {
        font-size: 3vw;
    }
}
@media screen and (min-width: 200px) and (max-width: 500px) {
    .ctaParent {
        min-height: 40vh;
        justify-content: center;
    }
    .ctaContent {
        min-width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .leftCTA,
    .rightCTA {
        justify-content: center !important;
    }
    .alignRight {
        margin-right: 0% !important;
    }
    .alignLeft {
        margin: 0% !important;
    }
    .ctaContent > h1 {
        font-size: 4.5vw;
    }
    .ctaContent > h4 {
        font-size: 4vw;
    }
    .ctaContent > a > button {
        font-size: 3.5vw;
    }
}

.rightCTA {
    justify-content: flex-end;
}

.leftCTA {
    justify-content: flex-start;
}

.ctaParent {
    position: relative;
    height: 30vw;
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-position: center;
    background-size: cover;
}

.gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.gradientRight {
    background: linear-gradient(
        313deg,
        rgba(0, 0, 0, 1) 20%,
        rgba(108, 108, 108, 0) 100%
    );
}

.gradientLeft {
    background: linear-gradient(
        133deg,
        rgba(0, 0, 0, 1) 20%,
        rgba(108, 108, 108, 0) 100%
    );
}

.large {
    padding: 12.5px 40px;
    font-weight: 500;
    border: 1px solid white;
}

.ctaContent {
    width: 35%;
    z-index: 1;
}

.alignRight {
    margin-right: 15%;
}

.alignLeft {
    margin-left: 15%;
}
