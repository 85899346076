.content-columns{
    margin-top: 22px;
}

@media screen and (max-width: 1000px) {
    .content-columns{
        margin: 0px;
        height:575px;
        width:514px;
    }
}
.image{
    width:300px; 
    height:300px;
}

.bg-white-06{
    overflow: hidden;
   
}

.heroImageSkillbridge {
    background-size: cover;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
}

.content-selection-skillbridge{
    width:440px !important;
     height:614px;
     overflow: hidden;
  }

.content-containe-skillbridge{
    max-width: 421px;
    min-width: 1vw;
    border-radius: 20px;
    height: 570px;
}

#zeros-flex{
    border-radius: 10px;
}